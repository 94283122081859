




import { Component, Vue } from 'vue-property-decorator'
import CallDetail from './components/CallDetail.vue'

@Component({
  name: 'EditCall',
  components: {
    CallDetail
  }
})
export default class extends Vue {}
